* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  color: #aaa;
  margin: 0;
  padding: 0;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
}

body {
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

#book_wrapper {
  width: 100%;
  max-width: 100vh;
}

#controls {
  justify-content: center;
  align-items: center;
  display: flex;
}

#controls #prev, #controls #next {
  display: none;
}

@media screen and (min-width: 1000px) {
  #controls #prev, #controls #next {
    cursor: pointer;
    padding: .5rem;
    display: block;
  }

  #controls #prev span, #controls #next span {
    transition: transform .25s;
    display: block;
  }

  #controls #prev:hover span {
    transform: translateX(-5px);
  }

  #controls #next:hover span {
    transform: translateX(5px);
  }
}

/*# sourceMappingURL=index.b219c132.css.map */
