*{
    box-sizing: border-box;
}

html, body{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    color: #aaa;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    background-color: #000;
    padding: 1rem;
}

#book_wrapper{
    width: 100%;
    max-width: 100vh;
}

#controls{
    display: flex;
    justify-content: center;
    align-items: center;
    #prev, 
    #next{
        display: none;
    }
}

@media screen and (min-width: 1000px) {
    #controls{
    #prev, 
    #next{
        display: block;
        padding: .5rem;
        cursor: pointer;
    }
    #prev span, 
    #next span{
        display: block;
        transition: transform .25s;
    }
    #prev:hover span{
        transform: translateX(-5px);
    }
    #next:hover span{
        transform: translateX(5px);
    }
}
}